// Function to fetch the iframe URL from the server for question 77
function fetchIframeUrl77() {
    fetch('/metabase/iframeUrl77')
        .then(response => response.json())
        .then(data => {
            // Ensure that the fetched URL uses HTTPS
            data.iframeUrl = data.iframeUrl.replace(/^http:/, 'https:');
            // Update the src attribute of the first iframe with the fetched URL
            const iframe = document.getElementById('metabaseIframe77');
            if (iframe) {
                iframe.src = data.iframeUrl;

            } else {
                console.error('Error: Element with ID "metabaseIframe77" not found in the DOM.');
            }
        })
        .catch(error => console.error('Error fetching iframe URL for question 77:', error));
}

// Function to fetch the iframe URL from the server for question 79
function fetchIframeUrl79() {
    fetch('/metabase/iframeUrl79')
        .then(response => response.json())
        .then(data => {
            // Ensure that the fetched URL uses HTTPS
            data.iframeUrl = data.iframeUrl.replace(/^http:/, 'https:');
            // Update the src attribute of the second iframe with the fetched URL
            const iframe = document.getElementById('metabaseIframe79');
            if (iframe) {
                iframe.src = data.iframeUrl;

            } else {
                console.error('Error: Element with ID "metabaseIframe79" not found in the DOM.');
            }
        })
        .catch(error => console.error('Error fetching iframe URL for question 79:', error));
}

// Function to fetch the iframe URL from the server for question 80
function fetchIframeUrl80() {
    fetch('/metabase/iframeUrl80')
        .then(response => response.json())
        .then(data => {
            // Ensure that the fetched URL uses HTTPS
            data.iframeUrl = data.iframeUrl.replace(/^http:/, 'https:');
            // Update the src attribute of the second iframe with the fetched URL
            const iframe = document.getElementById('metabaseIframe80');
            if (iframe) {
                iframe.src = data.iframeUrl;

            } else {
                console.error('Error: Element with ID "metabaseIframe80" not found in the DOM.');
            }
        })
        .catch(error => console.error('Error fetching iframe URL for question 80:', error));
}

// Call the fetchIframeUrl functions when the page loads
window.onload = function() {
    // fetchIframeUrl77();
    // fetchIframeUrl79();
    // fetchIframeUrl80();
};
