const Typed = require('typed.js');

// Function to handle the backspacing without line breaks
function onStringBackspace(arrayPos, self) {
  const str = self.strings[arrayPos];
  const lineBreaks = str.match(/\n/g); // Check for line breaks
  const lineBreakCount = lineBreaks ? lineBreaks.length : 0;

  if (lineBreakCount > 0) {
    // If there are line breaks, set the backspace amount to the length of the entire string
    return str.length;
  }

  // Default behavior, backspace one character at a time
  return 1;
}

// Initialize Typed instances
const toggles = document.querySelectorAll('[data-typed]');
const togglesAppear = document.querySelectorAll('[data-appear]');

toggles.forEach((toggle) => {
  const elementOptions = toggle.dataset.typed ? JSON.parse(toggle.dataset.typed) : {};

  const defaultOptions = {
    typeSpeed: 20,
    backSpeed: 30,
    backDelay: 3000,
    showCursor: false,
    loop: true,
    onStringBackspace: onStringBackspace, // Use the custom backspacing function
  };

  const options = {
    ...defaultOptions,
    ...elementOptions,
  };

  new Typed(toggle, options);
});

togglesAppear.forEach((toggle) => {
  const elementOptions = toggle.dataset.appear ? JSON.parse(toggle.dataset.appear) : {};

  const defaultOptions = {
    typeSpeed: 30,
    backSpeed: 40,
    backDelay: 4000,
    loop: true,
    showCursor: false,
 // Use the custom backspacing function
  };

  const options = {
    ...defaultOptions,
    ...elementOptions,
  };

  new Typed(toggle, options);
});

// Make available globally
window.Typed = Typed;
