const Isotope = require('isotope-layout');
const imagesLoaded = require('imagesloaded');
import 'isotope-fit-columns';

document.addEventListener('DOMContentLoaded', function () {
  var grid = document.querySelector('.grid');
  var $grid = new Isotope(grid, {
    itemSelector: '.grid-item',
    layoutMode: 'fitColumns',

    hiddenStyle: {
      opacity: 0
    },
    visibleStyle: {
      opacity: 1
    },
  });

  imagesLoaded(grid, function () {
    // Your other code for click events and filtering
    document.getElementById('grid-filter').addEventListener('click', function (event) {
      if (event.target.tagName === 'A') {
        event.preventDefault();
        var buttonFilter = event.target.getAttribute('data-category');
        
        // Use the filter method directly
        $grid.arrange({ filter: buttonFilter });
      }
    });

    // ... Other event listeners and code
  });
});
