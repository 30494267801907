// search.js

import { AssetsChart, ExpensesChart, RevenueChart, LiabilitiesChart, RevExpChart, AssLibChart, TtlEmplyCntChart, TotalCompChart, AvgCompChart, CompExpChart, updateCharts } from './chart-cards';

document.addEventListener('DOMContentLoaded', () => {
  const input = document.getElementById('searchQuery');
  const searchResultsContainer = document.getElementById('searchResultsContainer');

  // Check if the searchResultsContainer element exists before using it
  if (!searchResultsContainer) {
    return; // Exit the function if searchResultsContainer is not defined
  }

  const logo = document.getElementById('searchLogo');
  const orgCacheDisplay = document.getElementById('orgCacheDisplay');

  let clickedInsideInput = false; // Initialize a variable to track if the input was clicked

  // Add a focus event listener to the input to set clickedInsideInput to true when it's clicked
  input.addEventListener('focus', () => {
    clickedInsideInput = true;
  });

  // Define a debounce function with a 300ms delay
  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  // Function to show the blur effect on the logo
  const showBlurEffect = () => {
    logo.classList.add('blur-effect');
  };

  // Function to hide the blur effect on the logo
  const hideBlurEffect = () => {
    logo.classList.remove('blur-effect');
  };

  // Function to hide orgCacheDisplay
  const hideOrgCacheDisplay = () => {
    orgCacheDisplay.textContent = ''; // Clear the content
  };

  // Function to fetch and display results
  const fetchAndDisplayResults = () => {
    showBlurEffect(); // Show the blur effect when fetching results

    const query = input.value.trim();
    if (!query) {
      searchResultsContainer.innerHTML = '';
      hideBlurEffect(); // Hide the blur effect when results are ready
      hideOrgCacheDisplay(); // Hide orgCacheDisplay when input is empty
      return;
    }

    fetch('https://localhost:3002/api/search', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ searchQuery: query }),
    })
      .then(response => response.json())
      .then(data => {
        searchResultsContainer.innerHTML = '';
        hideBlurEffect(); // Hide the blur effect when results are ready
        hideOrgCacheDisplay(); // Hide orgCacheDisplay when results are displayed

        if (data.length === 0) {
          searchResultsContainer.textContent = 'No results found';
        } else {
          const topResults = data.slice(0, 20);

          topResults.forEach(result => {
            console.log('Result:', result);
            const orgLink = document.createElement('a');
            orgLink.href = '#orgLink';
            orgLink.style.whiteSpace = 'pre-line';
            orgLink.textContent = `${result.name}\n${result.city}, ${result.state} - ${result.ein}`;

            orgLink.style.borderBottom = '0.25px solid #000';

            orgLink.addEventListener('click', async (event) => {
              event.preventDefault();
              searchResultsContainer.innerHTML = '';

              // Log a message to confirm that the event is triggered
              console.log('Result clicked:', result.name);

              // Retrieve the name and ein from the clicked element's attributes or data attributes
              const name = result.name;
              const ein = result.ein;

              // Update the input field with name
              input.value = `${name}`;

              // Fetch and display organization data here using name and ein
              try {
                const response = await fetch(`https://localhost:3002/api/org/${ein}`);
                const orgData = await response.json();
                console.log('Org Data:', orgData);

                // Display the organization data in the orgCacheDisplay without line breaks
                if (orgData) {
                  orgCacheDisplay.textContent = JSON.stringify(orgData, null, 2);
                  AssetsChart(orgData); // Pass orgData to your chart functions
                  RevenueChart(orgData);
                  LiabilitiesChart(orgData);
                  ExpensesChart(orgData);
                  RevExpChart(orgData);
                  AssLibChart(orgData);
                  TtlEmplyCntChart(orgData);
                  TotalCompChart(orgData);
                  AvgCompChart(orgData);
                  CompExpChart(orgData);
                } else {
                  orgCacheDisplay.textContent = 'Organization data not found';
                }

                // Commented out the following line related to filing data
                // Call fetchAndDisplayfilingData with the selected ein
                // fetchAndDisplayfilingData(ein);
              } catch (error) {
                console.error('Error:', error);
              }
            });

            const linkContainer = document.createElement('div');
            linkContainer.appendChild(orgLink);

            searchResultsContainer.appendChild(linkContainer);
          });
        }
      })
      .catch(error => {
        console.error('Error:', error);
        hideBlurEffect(); // Hide the blur effect in case of an error
        hideOrgCacheDisplay(); // Hide orgCacheDisplay when there's an error
      });
  };

  // Create a debounced version of fetchAndDisplayResults with a 300ms delay
  const debouncedFetch = debounce(fetchAndDisplayResults, 300);

  input.addEventListener('input', debouncedFetch);

  const searchButton = document.getElementById('searchButton');
  searchButton.addEventListener('click', fetchAndDisplayResults);

  // Add event listener for keydown event on the input
  input.addEventListener('keydown', (event) => {
    // Check if the Enter key is pressed
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent the default form submission behavior
      const firstResult = searchResultsContainer.querySelector('a'); // Get the first result
      if (firstResult) {
        firstResult.click(); // Click the first result
      }
    }
  });

  // Add event listener for click event on the window to refresh when a search result is clicked
  window.addEventListener('click', () => {
    if (clickedInsideInput) {
      fetchAndDisplayResults();
      clickedInsideInput = false; // Reset the clickedInsideInput flag
    }
  });

});
