// filings-cache.js

// Check if the searchResultsContainer element exists before using it
const searchResultsContainer = document.getElementById('searchResultsContainer');
if (searchResultsContainer) {
  // Function to fetch and display filing data
  const fetchAndDisplayfilingData = (ein) => {
    // Fetch filing data for the selected EIN
    fetch(`https://localhost:3002/api/filings/${ein}`)
      .then(response => response.json())
      .then(filingData => {
        // Process and display the filing data as needed
        console.log('Fetched filing data:', filingData);
        // You can update the UI with the filing data here
      })
      .catch(error => {
        console.error('Error fetching filing data:', error);
      });
  };

  // Event listener when an organization is selected
  searchResultsContainer.addEventListener('click', (event) => {
    const clickedElement = event.target;
    const ein = clickedElement.getAttribute('data-ein');

    if (ein) {
      // Call fetchAndDisplayfilingData with the selected EIN
      fetchAndDisplayfilingData(ein);
    }
  });
} else {
  // console.error('Error: searchResultsContainer not found');
}
