//
// highlight.js
//

const hljs = require('highlight.js/lib/core');
const javascript = require('highlight.js/lib/languages/javascript');
const xml = require('highlight.js/lib/languages/xml');

const highlights = document.querySelectorAll('.highlight');

// Only register the languages we need to reduce the download footprint
hljs.registerLanguage('xml', xml);
hljs.registerLanguage('javascript', javascript);

highlights.forEach((highlight) => {
  hljs.highlightBlock(highlight);
});

// Make available globally
window.hljs = hljs;
